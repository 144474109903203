@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
  scroll-behavior: smooth;
  height: 100% !important;
}

body {
  min-height: 100% !important;
}

#root {
  min-height: 100vh !important;
}

a {
  text-decoration: none !important;
}

#slide-navbar {
  width: 320px !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #E8EBFF;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #ADBAFF;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7388FF;
}
